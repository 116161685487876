import React from "react";
import styled, { keyframes, css } from "styled-components/macro";
import { Switch, Route } from "react-router-dom";
import { detect } from "detect-browser";
import Header from "./components/Header";
import Scooter from "./views/Scooter";
import ScooterRoadmap from "./views/ScooterRoadmap";

const browser = detect();
console.log(browser);

const blur = keyframes`
  from {
    filter: blur(0px) brightness(1);
    opacity: 1;
  }

  to {
    filter: blur(3px) brightness(0.55);
    opacity: 0.5;
  }
`;

const reverseBlur = keyframes`
  from {
    filter: blur(4px) brightness(0.6);
  }

  to {
    filter: blur(0px) brightness(1);
  }
`;

const VideoWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  overflow: hidden;

  // Firefox has poor performance with blur filters, so let's not use it
  ${/firefox|ios|android/.test(browser.name) &&
  css`
    filter: blur(3px) brightness(0.55);
    opacity: 0.5;
  `}
  ${!/firefox|ios|android/.test(browser.name) &&
  css`
    animation: ${blur} 2s ease-in-out 1s forwards;
  `}

  & video {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    object-fit: cover;
  }
`;

const Content = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;

  // Firefox has poor performance with blur filters, so let's not use it
  ${!/firefox|ios|android/.test(browser.name) &&
  css`
    filter: blur(4px) brightness(0.6);
    animation: ${reverseBlur} 2s ease-in-out 1s forwards;
  `}
`;

const Root = styled.div`
  border-left: 1px solid #a1ffff;
  border-right: 1px solid #a1ffff;
  width: 1060px;
  height: fit-content;
  min-height: 100vh;
  position: relative;

  @media (max-width: 1100px) {
    width: calc(100% - 42px);
  }
`;

const Main = styled.main`
  position: relative;
  padding: 42px 24px;
`;

function App() {
  return (
    <div className="App">
      <Content>
        <Root>
          <Header />
          <Main>
            <Switch>
              <Route exact path="/products">
                Products
              </Route>
              <Route exact path="/products/scooter">
                <Scooter />
              </Route>
              <Route exact path="/products/scooter/roadmap">
                <ScooterRoadmap />
              </Route>
              <Route exact path="/services">
                Services
              </Route>
              <Route exact path="/contact">
                Contact
              </Route>
            </Switch>
          </Main>
        </Root>
      </Content>

      <VideoWrapper>
        <video
          width="100%"
          loop
          autoPlay
          muted
          playsInline
          poster={`${process.env.PUBLIC_URL}/assets/video/bg-poster.jpg`}
        >
          {!/firefox|ios|android/.test(browser.name) && (
            <>
              <source
                src={`${process.env.PUBLIC_URL}/assets/video/bg-1080p.webm`}
                type="video/webm"
              />
              <source
                src={`${process.env.PUBLIC_URL}/assets/video/bg-1080p.mp4`}
                type="video/mp4"
              />
              <track kind="captions" />
            </>
          )}
        </video>
      </VideoWrapper>
    </div>
  );
}

export default App;
