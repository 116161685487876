import styled from "styled-components/macro";

const SideTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0 0 45px;
  width: 45px;
  height: fit-content;
  font-size: 36px;
  line-height: 49px;
  font-weight: 700;
  word-wrap: break-word;
  text-transform: uppercase;
  color: #f2397c;
  text-align: center;

  @media (max-width: 900px) {
    display: block;
    width: auto;
    margin-bottom: 32px;
  }

  & > div {
    margin-top: 14px;
    width: 33px;

    @media (max-width: 900px) {
      margin-top: 0;
      width: auto;
    }
  }
`;

export default SideTitle;
