import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  /**
   * Reset
   *  http://meyerweb.com/eric/tools/css/reset/
   * v2.0 | 20110126
   * License: none (public domain)
   */

  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  body {
    line-height: 1;
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }


  * {
    box-sizing: border-box;
  }


  /**
   * Fill the window
   */
  html {
    position: relative;
    height: 100%;
  }

  body, #root {
    position: relative;
    width: 100%;
    min-height: 100%;
    background-color: #0B0C0D;
    color: #E5FFFE;
    font-family: 'Open Sans', sans-serif;
  }

  a {
    text-transform: uppercase;

    &:link {
      color: #E5FFFE;
      text-decoration: none;
    }

    &:visited {
      color: #E5FFFE;
    }
  }

  p {
    font-size: 18px;
    line-height: calc(18px * 1.61);
    margin-bottom: calc(18px * 1.61);

    &:last-child {
      margin-bottom: 0;
    }
  }

  ul {
    font-size: 18px;
    line-height: calc(18px * 1.61);
    margin-bottom: calc(18px * 1.61);
    list-style-type: circle;
    list-style-position: inside;

    &.outside {
      list-style-position: outside;
      padding-left: 20px;

      & li {
        margin-bottom: calc(18px * 1.61);
      }
    } 

    & li::before {
    color: #A1FFFF;
    }
  }

  /* proportionally scale images */
  img {
    width: 100%;
    height: auto;
    height: intrinsic; /* for safari */
  }

  h3 {
    color: #A1FFFF;
    text-transform: uppercase;
    font-size: 28px;
    line-height: calc(28px * 1.61);
    margin-bottom: 17px;
  }

  h6 {
    color: #FF93BA;
    text-transform: uppercase;
    font-size: 16px;
    line-height: calc(16px * 1.61);
  }

  emphasis {
    color: #A1FFFF;
    text-transform: uppercase;
    font-size: 20px;
    line-height: calc(20px * 1.61);
  }

`;

export default GlobalStyle;
