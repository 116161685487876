import React from "react";
import styled, { css } from "styled-components/macro";

const SectionWrapper = styled.section`
  & .row {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: calc(28px * 1.61);

    @media (max-width: 900px) {
      display: block;
    }

    & div {
      flex: 1 1 300px;
    }

    & .graphic {
      display: flex;
      justify-content: center;
      flex: 0 0 200px;

      @media (max-width: 900px) {
        width: 100%;
        text-align: center;

        ${(props) =>
          !props.right &&
          css`
            padding-bottom: 28px;
          `}

        ${(props) =>
          props.right &&
          css`
            padding-top: 28px;
          `}
      }
    }
  }
`;

const Section = ({ title, graphic, width, right = false, children }) => {
  return (
    <SectionWrapper right={right}>
      <h3>{title}</h3>

      <div className="row">
        {!right && (
          <>
            {graphic && (
              <div className="graphic" style={{ flexBasis: width }}>
                {graphic}
              </div>
            )}
            <div>{children}</div>
          </>
        )}

        {right && (
          <>
            <div>{children}</div>
            {graphic && (
              <div className="graphic" style={{ flexBasis: width }}>
                {graphic}
              </div>
            )}
          </>
        )}
      </div>
    </SectionWrapper>
  );
};

export default Section;
