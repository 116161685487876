import React from "react";
import { ReactComponent as Brand } from "../assets/img/48s.svg";
import styled from "styled-components/macro";
import { Link, NavLink } from "react-router-dom";

const Root = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  left: -20px;
  width: 1100px;
  height: 77px;
  border-bottom: 1px solid #a1ffff;
  padding: 0 20px;

  @media (max-width: 1099px) {
    width: calc(100vw - 18px);
    height: auto;
  }
`;

const Left = styled.div`
  flex: 1 1 25px;
  display: flex;
  padding: 8px 20px;
`;

const Right = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1 1 150px;

  @media (max-width: 600px) {
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-end;
    text-align: right;
    padding: 18px 0;
  }
`;

const StyledNavLink = styled(NavLink)`
  margin: 0 24px;
  font-size: 18px;
  line-height: calc(18px * 1.61);
  white-space: nowrap;

  &:hover {
    color: #ff93ba;
  }

  &.active {
    color: #f2397c;
  }
`;

const Header = () => {
  return (
    <Root>
      <Left>
        <Link to="/">
          <Brand />
        </Link>
      </Left>
      <Right>
        <StyledNavLink to="/products">Products</StyledNavLink>
        <StyledNavLink to="/services">Services</StyledNavLink>
        <StyledNavLink to="/contact">Contact Us</StyledNavLink>
      </Right>
    </Root>
  );
};

export default Header;
