import React, { useEffect } from "react";
import styled from "styled-components/macro";
import { ReactComponent as ScooterIcon } from "../assets/img/scooter.svg";
import { ReactComponent as AgnosticImg } from "../assets/img/scooter-agnostic.svg";
import { ReactComponent as DeterministicImg } from "../assets/img/scooter-deterministic.svg";
import { ReactComponent as DecentralizedImg } from "../assets/img/scooter-decentralized.svg";
import { ReactComponent as SecureImg } from "../assets/img/scooter-secure.svg";
import { ReactComponent as FlashlightImg } from "../assets/img/scooter-flashlight.svg";
import { ReactComponent as RestImg } from "../assets/img/scooter-rest.svg";
import { ReactComponent as SCCImg } from "../assets/img/scooter-scc.svg";
import imgDepTree from "../assets/img/scooter-trees.png";
import SideTitle from "../components/SideTitle";
import Section from "../components/Section";

const Root = styled.div`
  display: flex;
  @media (max-width: 900px) {
    display: block;
  }
`;

const Doc = styled.div`
  flex: 1 1 100px;
  padding-left: 29px;

  @media (max-width: 900px) {
    padding-left: 0;
  }
`;

const H6 = styled.h6`
  @media (max-width: 900px) {
    padding-bottom: 28px;
    text-align: center !important;
  }
`;

const Scooter = () => {
  useEffect(() => {
    document.title = "48s : Scooter";
  }, []);

  return (
    <Root>
      <SideTitle>
        <ScooterIcon />
        <div>Scooter</div>
      </SideTitle>
      <Doc>
        <p style={{ marginBottom: 28 }}>
          <i>
            A modern platform for digital asset management and dependency
            tracking.
          </i>
        </p>

        <p>
          Scooter helps companies manage the complexity of building, sharing and
          storing digital assets. From concept to hundreds of terabytes of data,
          Scooter grows along with you. Any size. Any type. Any location. Save
          time and money by reusing your content with advanced searching and
          archiving.
        </p>

        <p>
          Focus on what you do best, creating, and leave the asset tracking,
          storage and sharing up to Scooter.
        </p>

        <H6 style={{ textAlign: "right" }}>
          Limited Private Beta October 2020
        </H6>

        <Section title="Agnostic" graphic={<AgnosticImg />}>
          <>
            <p>
              Scooter is a general purpose platform that manages any kind of
              digital asset. It takes your unstructured data and turns it into
              trackable, searchable and sharable information. It can handle
              large quantities of files and large file sizes efficiently, using
              standard and secure protocols.
            </p>
          </>
        </Section>

        <Section
          title="Dependency Trees"
          right
          width={288}
          graphic={
            <div>
              <img
                style={{ width: 288, opacity: 0.8 }}
                src={imgDepTree}
                alt="Dependency Trees"
              />
            </div>
          }
        >
          <>
            <p>
              What makes Scooter so powerful is that assets can be composed of
              other assets. Perhaps you're working on content that has a chain
              of dependencies, like an image assembled from other images, a song
              with multiple nested audio tracks, or a document that references
              other documents. Scooter helps manage this complexity by
              categorizing asset dependencies as 2 different types:
            </p>

            <ul>
              <li>Content required only while authoring an asset</li>
              <li>
                Items that must accompany an asset when consumed downstream
              </li>
            </ul>

            <p>
              Quickly inspect an asset to determine the entire lineage of
              content that went in to building that asset.
            </p>
          </>
        </Section>

        <Section title="Repeatable" graphic={<DeterministicImg />}>
          <>
            <p>
              We take the approach that assets are temporary and can be safely
              removed at any time. To accomplish this we made Scooter
              deterministic, recording all assets that have been installed. The
              manifest allows us to confidently recreate the state of all the
              installed assets and their dependencies.
            </p>
          </>
        </Section>

        <Section title="Git / Perforce Integration" graphic={<SCCImg />} right>
          <>
            <p>
              Scooter plays nicely with source code control solutions like Git,
              Perforce, Mercurial, etc. Since Scooter records all asset events,
              you only need to commit the small manifest and safely ignore those
              large assets that can bog down your version control tool. Because
              Scooter is deterministic, the asset state can be confidently
              recreated from the manifest. This lets you leverage a system
              that’s purposely built for transferring large files and large
              amounts of files within your current workflows.
            </p>
          </>
        </Section>

        <Section title="Searchable" graphic={<FlashlightImg />}>
          <>
            <p>
              Want to find an asset from a previous project? Simply use the
              built in exhaustive search engine purposely built for combing
              through vast amounts of data. We wrap up the complexity and allow
              customers to utilize complex filters through our REST API, Command
              Line Interface (CLI) and native applications.
            </p>
            <p>
              Add your own metadata to an asset and have it automatically
              indexed and included in the search engine.
            </p>
          </>
        </Section>

        <Section title="Decentralized" graphic={<DecentralizedImg />} right>
          <>
            <p>
              We adhere to a "remote first" mind set. Even if you’re in a
              co-located work environment, everyone interacts with Scooter's
              decentralized infrastructure the same way. No more shared storage
              with complex hierarchy of directories that are used as a database.
            </p>
            <p>
              Our global fault-tolerant infrastructure allows us to geo-locate
              your assets, putting the content closest to the consumer and
              transferring at the highest possible throughput.
            </p>
          </>
        </Section>

        <Section title="Secure" graphic={<SecureImg />}>
          <>
            <p>
              Everything Scooter does is secure. Data is encrypted in transit
              via TLS v1.2 and encrypted at rest with user definable AES256
              keys. We include an OAuth 2.0 service for authentication and
              authorization with fine grained role base access control (RBAC) at
              the organization, project, team or even file level. Credentials
              can be immediately revoked if necessary.
            </p>
          </>
        </Section>

        <Section title="Application Integrations" graphic={<RestImg />} right>
          <>
            <p>
              Scooter's platform makes it easy to integrate digital assets into
              your application of choice. We provide a highly performant REST
              API for managing the life cycle of your digital assets. All of our
              native applications for Mac, Linux or Windows leverage this same
              REST API.
            </p>
          </>
        </Section>
      </Doc>
    </Root>
  );
};

export default Scooter;
