import React, { useEffect } from "react";
import styled from "styled-components/macro";
import { ReactComponent as ScooterIcon } from "../assets/img/scooter.svg";
import Section from "../components/Section";
import SideTitle from "../components/SideTitle";

const Root = styled.div`
  display: flex;
  @media (max-width: 900px) {
    display: block;
  }
`;

const Doc = styled.div`
  flex: 1 1 100px;
  padding-left: 29px;

  @media (max-width: 900px) {
    padding-left: 0;
  }
`;

const Scooter = () => {
  useEffect(() => {
    document.title = "48s : Scooter Roadmap";
  }, []);

  return (
    <Root>
      <SideTitle>
        <ScooterIcon />
        <div>Roadmap</div>
      </SideTitle>
      <Doc>
        <Section title="Q4 2020">
          <ul className="outside">
            <li>
              <emphasis>Reports</emphasis>
              <br />
              Detailed consumption reports at organization, project, and team
              levels.
            </li>
            <li>
              <emphasis>Virtual Workstation Management</emphasis>
              <br />
              An easy to use interface that wraps up the complexities
              provisioning and managing remote workstations in the cloud.
            </li>
            <li>
              <emphasis>Workflow Management</emphasis>
              <br />
              An opinionated way to manage versions while creating assets
              integrated in to Scooter.
            </li>
            <li>
              <emphasis>Local Caching</emphasis>
              <br />
              Store and reuse local copies of remote assets to decrease
              bandwidth consumption. Run at the facility or datacenter level or
              directly on workstations.
            </li>
          </ul>
        </Section>

        <Section title="Q1 2021">
          <ul className="outside">
            <li>
              <emphasis>Production Management</emphasis>
              <br />
              Provides an integrated interface for scheduling and budgeting the
              creation of assets.
            </li>
            <li>
              <emphasis>Deduplication / Hoisting</emphasis>
              <br />
              Automatically handle duplication of content at storage and on
              installation.
            </li>
          </ul>
        </Section>

        <Section title="Q2 2021">
          <ul className="outside">
            <li>
              <emphasis>Review Tools</emphasis>
              <br />
              Playlist generation and sharing with synchronized browser playback
              and annotations.
            </li>
            <li>
              <emphasis>Job Processing</emphasis>
              <br />
              Integrated remote batch processing for tasks such as training for
              machine learning, rendering, simulation, CI/CD, etc.
            </li>
          </ul>
        </Section>
      </Doc>
    </Root>
  );
};

export default Scooter;
